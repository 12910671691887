import { Link } from "react-router-dom";
import { useState } from "react";

import "./Header.css";
import hamburger from "../assets/icons/textalign-right.svg";
import closeHamburger from "../assets/icons/close.svg";
import logo from "../assets/images/my-logo.png";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const closeMobileMenu = () => {
    setIsNavExpanded(false);
    console.log(isNavExpanded);
  };
  return (
    <div className={isNavExpanded ? "header_ctn_extended" : "header_ctn"}>
      <div className="header_inner">
        <div className="logo">
          <Link to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
        </div>
        <div className="menu">
          <Link className="menu_item" to="/">
            Home
          </Link>
          <Link className="menu_item" to="/about">
            About
          </Link>
          <Link className="menu_item" to="/resume">
            Resume
          </Link>
          <Link className="menu_item" to="/portfolio">
            Portfolio
          </Link>
          <Link className="menu_item" to="/blog">
            Blog
          </Link>
        </div>
        <div className="mobile_bar">
          <img
            src={isNavExpanded ? closeHamburger : hamburger}
            alt=""
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          />
          {/* <FontAwesomeIcon icon="fa-solid fa-bars" className="hamburger" /> */}
        </div>
      </div>
      {isNavExpanded && (
        <div className="menu-container">
          <div className="mobile_menu_inner">
            <Link to="/" onClick={closeMobileMenu}>
              <p className="menu-text">Home</p>
            </Link>
            <Link to="/about" onClick={closeMobileMenu}>
              <p className="menu-text">About</p>
            </Link>
            <Link to="/resume" onClick={closeMobileMenu}>
              <p className="menu-text">Resume</p>
            </Link>
            <Link to="/portfolio" onClick={closeMobileMenu}>
              <p className="menu-text">Portfolio</p>
            </Link>
            <Link to="/blog" onClick={closeMobileMenu}>
              <p className="menu-text">Blog</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
