import { TypeAnimation } from "react-type-animation";
// import { Helmet } from "react-helmet-async";
import "./Home.css";

import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <div>
      <div className="container">
        <div className="ctn_overlay">
          <div className="home_content">
            <h1 className="username">David Emaye</h1>
            <div className="user_skills">
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "Frontend Developer",
                  1000,
                  "Mobile Engineer",
                  1000,
                  // "UI/UX Designer",
                  // 1000,
                  "Technical Writer",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ fontSize: "2em", display: "inline-block" }}
                repeat={Infinity}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
