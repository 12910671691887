import "./Footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className="footer_ctn">
      <div className="inner">
        <a
          href="https://www.linkedin.com/in/davidemaye"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-linkedin"
            className="social_icon"
          />
        </a>
        <a
          href="https://www.instagram.com/emayedavid"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-instagram"
            className="social_icon"
          />
        </a>
        <a
          href="https://twitter.com/davidemaye"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-twitter"
            className="social_icon"
          />
        </a>
        {/* <a
          href="https://web.facebook.com/emaye.david"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon="fa-brands fa-square-facebook"
            className="social_icon"
          />
        </a> */}
      </div>
    </div>
  );
};

export default Footer;
