// import ConditionRendering from "../assets/images/conditional_rendering_in_nuxt.jpg";
import linkIcon from "../assets/icons/ArrowSquareOut.svg"

const BlogPost = (props) => {
  const bgStyle = (src) => ({
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  });

  return (
    <div className="blog_items_ctn">
      <div className="item_image" style={bgStyle(props.img)}></div>
      <div className="item_content">
        <p className="item_header">
          {props.title}
        </p>
        <p className="item_text">
          {props.desc}
        </p>
        <a
          className="item_link"
          href={props.url}
          target="_blank"
          rel="noreferrer"
        >
          <button className="item_btn">
            Read Article
            <span className="btn_span">
              <img src={linkIcon} alt="" />
            </span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default BlogPost;
