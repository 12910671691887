import { Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import ReactDOM from 'react-dom'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

import Homepage from "./pages/Home";
import AboutPage from "./pages/About";
import ResumePage from "./pages/Resume";
import PortfolioPage from "./pages/Portfolio";
import BlogPage from "./pages/Blog";
import NotFound from "./pages/NotFound";

import Header from "./components/Header";

import "./App.css";
// import MobileMenu from "./components/MobileMenu";
const App = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>David Emaye</title>
        <meta
          name="description"
          content="Explore David's portfolio, a skilled Frontend Engineer and Technical Writer. Discover insightful blog posts on web development, technical writing, and cutting-edge technologies. Learn about his projects, skills, and expertise in creating seamless user experiences and clear, concise documentation."
        ></meta>
        <link rel="canonical" href="https://davidemaye.com/" />
      </Helmet>
      <div className="app_ctn">
        <div className="header">
          <Header />
        </div>
        {/* <div className="mobile">
        <MobileMenu />
      </div> */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
};

export default App;
library.add(fab, fas, far);
