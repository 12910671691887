// import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { request } from "graphql-request";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import ChekkerLogo from "../assets/images/cheekker-logo.jpg";
// import GazelleLogo from "../assets/images/Gazelle-Project.png";
// import ChallengeMeLogo from "../assets/images/challengeMe-logo.jpg";
// import SMELogo from "../assets/images/SME_mall_log0.jpg";
// import StartX from "../assets/images/startXlogo.jpg";

import "./Portfolio.css";
import Transitions from "../components/Transitions";

const PortfolioPage = () => {
  const bgStyle = (src) => ({
    background: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // background: isHover ? '#00000065' : 'red',
  });

  const [portfolios, setPortfolios] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPorfolio = async () => {
    setLoading(true);
    const { portfolios } = await request(
      "https://api-eu-west-2.hygraph.com/v2/cldo7exs20pnf01ul93gigt1v/master",
      `{
      portfolios (first: 100) {
        createdAt
        updatedAt
        type
        slug
        url
        coverPhoto {
          url
          fileName
        }
        id
        name
      }
    }`
    );
    setLoading(false);
    console.log(portfolios);
    setPortfolios(portfolios);
  };

  useEffect(() => {
    fetchPorfolio();
  }, []);

  const [activeTab, setActiveTab] = useState("All");

  const filteredPortfolios =
    activeTab === "All"
      ? portfolios
      : portfolios.filter((p) => p.type === activeTab);

  return (
    <HelmetProvider>
      <Helmet>
        <title>David Emaye's Portfolio</title>
        <meta
          name="description"
          content="Explore David's impressive projects, a dedicated Frontend Engineer and Technical Writer. Discover innovative web applications, responsive designs, and user-centric interfaces"
        ></meta>
        <link rel="canonical" href="https://davidemaye.com/" />
      </Helmet>
      <Transitions>
        <div className="port_ctn">
          <div className="port_inner">
            <div className="port_section">
              <p className="sub_head">Showcasing some of my best work</p>
              <h1 className="head">Portfolio</h1>
              <div className="port_content">
                <div className="port_tab">
                  <p
                    className={activeTab === "All" ? "activeTab" : ""}
                    onClick={() => setActiveTab("All")}
                  >
                    All
                  </p>
                  <p
                    className={activeTab === "WebApp" ? "activeTab" : ""}
                    onClick={() => setActiveTab("WebApp")}
                  >
                    WebApps/Websites
                  </p>
                  <p
                    className={activeTab === "Mobile" ? "activeTab" : ""}
                    onClick={() => setActiveTab("Mobile")}
                  >
                    Mobile Apps
                  </p>
                  <p
                    className={activeTab === "WordPress" ? "activeTab" : ""}
                    onClick={() => setActiveTab("WordPress")}
                  >
                    WordPress Websites
                  </p>
                  {/* <p
                    className={activeTab === "Design" ? "activeTab" : ""}
                    onClick={() => setActiveTab("Design")}
                  >
                    UI Designs
                  </p> */}
                </div>
                {loading && (
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <p className="loader_ctn">
                      <Skeleton count={3} />
                      <Skeleton count={3} />
                    </p>
                  </SkeletonTheme>
                )}
                {filteredPortfolios && (
                  <div className="port_items_ctn">
                    {filteredPortfolios.map((portfolio, index) => (
                      <a
                        key={index}
                        className="port_item"
                        style={bgStyle(portfolio.coverPhoto.url)}
                        href={portfolio.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="port_details">
                          <p className="port_name">{portfolio.name}</p>
                          <p className="port_type">{portfolio.type}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Transitions>
    </HelmetProvider>
  );
};

export default PortfolioPage;
