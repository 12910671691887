import Transitions from "../components/Transitions";
import BlogPost from "../components/BlogPost.jsx";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./Blog.css";
import { request } from "graphql-request";
import { useEffect, useState } from "react";

const BlogPage = () => {
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    setLoading(true)
    const { articles } = await request(
      "https://api-eu-west-2.hygraph.com/v2/cldo7exs20pnf01ul93gigt1v/master",
      `{
      articles (first: 100) {
        createdAt
        id
        slug
        title
        updatedAt
        coverPhoto {
          fileName
          url
        }
        content {
          text
        }
        url
      }
    }`
    );
    setLoading(false);
    // console.log(articles);
    setPosts(articles);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>David Emaye's Blog</title>
        <meta
          name="description"
          content="Delve into David's blog. Find expert insights, tutorials, and tips on web development, frontend technologies, and technical writing. Stay updated with the latest trends and best practices in the industry."
        ></meta>
        <link rel="canonical" href="https://davidemaye.com/" />
      </Helmet>
      <Transitions>
        <div className="blog_ctn">
          <div className="blog_inner">
            <div className="blog_section">
              <p className="sub_head">Check out my latest articles</p>
              <h1 className="head">My Blog</h1>
              {loading && (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <p className="blog_content">
                    <Skeleton marginBottom={100} height={300} count={3} />
                  </p>
                </SkeletonTheme>
              )}
              {posts && (
                <div className="blog_content">
                  {posts.map((post, index) => (
                    <BlogPost
                      title={post.title}
                      desc={post.content.text}
                      url={post.url}
                      key={index}
                      slug={post.slug}
                      img={post.coverPhoto?.url}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Transitions>
    </HelmetProvider>
  );
};

export default BlogPage;
