import { useState } from "react";
import "./Resume.css";

import Arrow from "../assets/images/resume-arrow.png";
import Transitions from "../components/Transitions";

const ResumePage = () => {
  const [experiences] = useState([
    {
      title: "Software Engineer",
      company: "Acumen Digital",
      date: "April 2021 - Present (Full-time)",
      workDone: [
        "Developing user-facing applications using Vue.js and Nuxt.js.",
        "Building modular and reusable components and libraries.",
        "Implementing automated testing integrated into development and maintenance workflows.",
        "Strengthened company brand and identity by creating distinctive Web Apps for clients.",
        "Built new mobile-friendly Web Apps and transitioned old site into mobile-friendly version.",
      ],
    },
    {
      title: "Mobile Engineer",
      company: "The RootsHive",
      date: "Feb 2023 - Present (Part-time)",
      workDone: [
        "Developing and maintaining The Rootshive mobile app using Flutter.",
        "Worked closely with designers to implement the user interface (UI) and user experience (UX) design as accurately as possible.",
        "Developed the app to work well on iOS and Android and followed the platform-specific design guidelines when necessary.",
        "Wrote and executed unit tests, integration tests, and widget tests to catch and fix bugs early in the development process.",
        "Integrated with external APIs and services as needed, whether for authentication, data retrieval, or other functionality.",
      ],
    },
    {
      title: "UI/UX Designer",
      company: "Kolhab Technologies",
      date: "February 2021 - October 2021 (Contract)",
      workDone: [
        "Creating user-centered designs by understanding business requirements, and user feedback.",
        "Translating requirements into style guides, design systems, design patterns and attractive user interfaces.",
        "Designing UI elements such as input controls, navigational components and informational components.",
        "Creating user flows, wireframes, prototypes and mockups.",
        "Identifying and troubleshooting UX problems (e.g. responsiveness).",
      ],
    },
    {
      title: "Frontend Developer",
      company: "MyChekker",
      date: "May 2022 - Dec 2022 (Contract)",
      workDone: [
        "Developed Chekker for Phlebotomist web app using Vue.js and Nuxt.js. ",
        "Made the web-app mobile friendly and integrated PWA for easy usage on mobile devices.",
        "Implementing automated testing integrated into development and maintenance workflows.",
        "Conducted training with the client and the team on how to use the web app.",
      ],
    },
    // {
    //   title: "",
    //   company: "",
    //   date: "",
    //   workDone: ["", "", "", ""],
    // },
  ]);
  return (
    <Transitions>
      <div className="resume_ctn">
        <div className="resume_inner">
          <div className="resume_section">
            <p className="sub_head">Check out my Resume</p>
            <h1 className="head">Resume</h1>
            <div className="resume_content">
              <div className="education_ctn edu_ctn">
                <p className="side_head">Education</p>
                <div className="education_ctn_inner">
                  <div className="education_item">
                    <div className="edu_arrow">
                      <img src={Arrow} alt="" />
                    </div>
                    <div>
                      <p className="edu_head">B.Tech Computer Science</p>
                      <p className="edu_sub_head">
                        Ondo State University of Sience and Technology / 2016 -
                        2022
                      </p>
                    </div>
                  </div>
                  <div className="education_item">
                    <div className="edu_arrow">
                      <img src={Arrow} alt="" />
                    </div>
                    <div>
                      <p className="edu_head">Digital Marketing</p>
                      <p className="edu_sub_head">
                        Google Digital Skills For Africa / January 2019
                      </p>
                    </div>
                  </div>
                  <div className="education_item">
                    <div className="edu_arrow">
                      <img src={Arrow} alt="" />
                    </div>
                    <div>
                      <p className="edu_head">
                        Front End Web Development (Practical Course)
                      </p>
                      <p className="edu_sub_head">Udemy / March 2020</p>
                    </div>
                  </div>
                  <div className="education_item">
                    <div className="edu_arrow">
                      <img src={Arrow} alt="" />
                    </div>
                    <div>
                      <p className="edu_head">
                        Responsible Leadership on Transparency and Good
                        Governance
                      </p>
                      <p className="edu_sub_head">
                        Yali Network / Febuary 2020
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="education_ctn">
                <p className="side_head">Experience</p>
                <div className="education_ctn_inner">
                  {experiences.map((experience, index) => (
                    <div className="education_item">
                      <div className="edu_arrow">
                        <img src={Arrow} alt="" />
                      </div>
                      <div>
                        <p className="edu_head">{experience.title}</p>
                        <p className="edu_sub_head">
                          <span className="company_name">
                            {experience.company}
                          </span>
                          {experience.date}
                        </p>
                        <div className="list_spec">
                          <ul>
                            {experience.workDone.map((workDone, index) => (
                              <li>{workDone}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default ResumePage;
