import "./About.css";
import myImage from "../assets/images/Image3.png";
import UserImage from "../assets/images/Dummy-image.jpeg";
import CV from "../assets/doc/DAVID Emaye's Resume.pdf";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Transitions from "../components/Transitions";

const AboutPage = () => {
  return (
    <Transitions>
      <div className="about_ctn">
        <div className="about_inner">
          <div className="about_section">
            <p className="sub_head">Get to know me</p>
            <h1 className="head">About Me</h1>
            <div className="about_content">
              <div className="lhs">
                <img className="user_image" src={myImage} alt="" />
              </div>
              <div className="rhs">
                <p className="about_text_1">Who am i?</p>
                <h1 className="about_text_2">
                  I'm David Emaye, a Frontend Developer, Mobile Engineer and a
                  Technical Writer
                </h1>
                <p className="text">
                  David Emaye is a Software Engineer, he has more than three years
                  commercial experience providing front-end development,
                  producing high quality responsive websites, cross platform mobile-apps and exceptional
                  user experience.
                </p>
                <p className="text">
                  The work he provides is of highest quality, fully responsive,
                  and tested in a wide range of devices. He takes great care to
                  ensure each project is well-documented and easily maintainable
                  so you can enhance a website as your company grows.
                </p>
                <p className="text">
                  His clients have found that launching with a well considered
                  and well developed front-end has saved them time and money and
                  makes for a product that is consistently of high quality.
                </p>
                <hr className="line" />
                <div className="personal_info">
                  <div className="info_ctn">
                    <p className="info">
                      Name: <span className="text sub_info">David Emaye</span>
                    </p>
                    <p className="info">
                      Email:
                      <span className="text sub_info">
                        <a href="mailto:emayeodavid@gmail.com">
                          emayeodavid@gmail.com
                        </a>
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="info">
                      From:{" "}
                      <span className="text sub_info">Lagos, Nigeria</span>
                    </p>
                  </div>
                  <a href={CV} download="David-Emaye-CV" target="_blank" rel="noreferrer">
                    <button className="donwnload_btn">Download CV</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="service_section">
            <p className="sub_head service_sub_head">
              Services i offer to my clients
            </p>
            <p className="head service_head">My Services</p>
            <div className="service_content">
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-brands fa-css3-alt"
                  style={{ color: "#6DB65B" }}
                  className="service_icon"
                />
                <p className="service_item_head">Front-end Development</p>
                <p className="service_item_text">
                  I create responsive websites that allow the user to experience
                  your website in the best and most appropriate way suited to
                  the device they are using.
                </p>
              </div>
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-brands fa-figma"
                  className="service_icon"
                />
                <p className="service_item_head">UI/UX Design</p>
                <p className="service_item_text">
                  I create user-friendly interfaces that enable users to
                  understand how to use complex technical products.
                </p>
              </div>
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-solid fa-globe"
                  className="service_icon"
                />
                <p className="service_item_head">Web Design</p>
                <p className="service_item_text">
                  I have excellent understanding of graphic design, colour
                  schemes, button design, interface design, Photoshop
                  /Illustrator, navigational design.
                </p>
              </div>
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-brands fa-css3-alt"
                  style={{ color: "#6DB65B" }}
                  className="service_icon"
                />
                <p className="service_item_head">WordPress Site Development</p>
                <p className="service_item_text">
                  In many projects, my client’s require the ability to manage
                  the content of their website. WordPress is the world’s most
                  popular content management system which I have many years of
                  experience with.
                </p>
              </div>
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-solid fa-pencil"
                  className="service_icon"
                />
                <p className="service_item_head">Technical Writing</p>
                <p className="service_item_text">
                  I create software documentation, user manuals, and other
                  technical content that converts complex technical information
                  to a non-technical language.
                </p>
              </div>
              <div className="service_item">
                <FontAwesomeIcon
                  icon="fa-solid fa-rocket"
                  className="service_icon"
                />
                <p className="service_item_head">Learn More</p>
                <p className="service_item_text">
                  If you’d like to find out more about how I work and my process
                  of working with clients, please send me a message in the
                  contact page and give a little information about the project
                  you have in mind.
                </p>
              </div>
            </div>
          </div>
          <div className="testimonial_section">
            <p className="sub_head service_sub_head">
              What my clients think about me
            </p>
            <p className="head service_head">Testimonials</p>
            <div className="testimonial_content">
              <div className="testimonial_slider">
                <div className="testimonial_item">
                  <div className="testi_top">
                    <div className="first_side">
                      <div className="testi_image">
                        <img src={UserImage} alt="" />
                      </div>
                      <div className="testi_details">
                        <p className="testi_name">David Salt Owumi</p>
                        <p className="testi_role">Founder Salt House</p>
                      </div>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-quote-right"
                        className="testi_icon"
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text">
                      David did an excellent creative job, addressing our
                      request quickly, and also providing her own graphic
                      insight, being open to feedback and changes or edits when
                      they arose. She worked with us the entire way. Highly
                      recommended.
                    </p>
                  </div>
                </div>
                <div className="testimonial_item">
                  <div className="testi_top">
                    <div className="first_side">
                      <div className="testi_image">
                        <img src={UserImage} alt="" />
                      </div>
                      <div className="testi_details">
                        <p className="testi_name">Ferdinand Ibezim</p>
                        <p className="testi_role">Entrepreneur</p>
                      </div>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-quote-right"
                        className="testi_icon"
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text">
                      David did an excellent creative job, addressing our
                      request quickly, and also providing her own graphic
                      insight, being open to feedback and changes or edits when
                      they arose. She worked with us the entire way. Highly
                      recommended.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default AboutPage;
