import Avatar from "../assets/images/2.png";
import Transitions from "../components/Transitions";
import "./NotFound.css";
import { Link } from 'react-router-dom'


const NotFound = () => {
  console.log('working');
  return (
    <Transitions>
      <div className="hero_ctn">
        <div className="hero_inner">
          <img className="avatar" src={Avatar} alt="" />
          <h1 className="hero_head">
            Oops<span className="spanned_text">, Seems you are lost</span>!
          </h1>
          <p className="hero_text">
            Sorry, the page you’re looking for does not exist
          </p>
          <div className="btns">
            <Link to="/">
              <button className="item_btn">Back to Home</button>
            </Link>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default NotFound;
